export default [
  {
    name: '账号注册',
    active: true,
    componentName: 'register',
    children: [
      {
        name: '企业开发者注册',
        componentName: 'register-register',
        component: () => import('./register/register.vue')
      },
      {
        name: '开发者平台服务协议',
        componentName: 'register-agreement',
        component: () => import('../login/agreement.vue')
      },
      {
        name: '开发者帐号与应用处置原因及措施',
        componentName: 'register-measure',
        component: () => import('./register/measure.vue')
      },
      {
        name: '开发者平台账号密码找回',
        componentName: 'register-findpass',
        component: () => import('./register/findpass.vue')
      },
      {
        name: '应用商店平台开发者合规公约',
        componentName: 'register-convention',
        component: () => import('./register/convention.vue')
      },
      {
        name: '注册常见Q&A',
        componentName: 'register-qa',
        component: () => import('./register/qa.vue')
      }
    ]
  },
  {
    name: '应用管理',
    active: false,
    componentName: 'manage',
    children: [
      {
        name: '应用提交',
        componentName: 'manage-submit',
        component: () => import('./manage/submit.vue')
      },
      {
        name: '版本更新',
        componentName: 'manage-update',
        component: () => import('./manage/update.vue')
      },
      {
        name: 'APP侵权投诉',
        componentName: 'manage-report',
        component: () => import('./manage/report.vue')
      },
      {
        name: 'APP侵权投诉反通知',
        componentName: 'manage-notice',
        component: () => import('./manage/notice.vue')
      },
      {
        name: '违规应用举报流程',
        componentName: 'manage-illegal',
        component: () => import('./manage/illegal.vue')
      },
      {
        name: '应用冻结及解冻流程',
        componentName: 'manage-freeze',
        component: () => import('./manage/freeze.vue')
      },
      {
        name: '应用违规处罚规范',
        componentName: 'manage-rule',
        component: () => import('./manage/rule.vue')
      },
      {
        name: '应用管理FAQ',
        componentName: 'manage-faq',
        component: () => import('./manage/faq.vue')
      }
    ]
  },
  {
    name: '应用联运',
    active: false,
    componentName: 'union',
    children: [
      {
        name: '联运业务介绍',
        componentName: 'union-intro',
        component: () => import('./union/intro.vue')
      },
      {
        name: '规则说明',
        componentName: 'union-rule',
        component: () => import('./union/rule.vue')
      }
    ]
  },
  {
    name: '能力接入',
    active: false,
    componentName: 'api',
    children: [
      // {
      //   name: '家长代付',
      //   componentName: 'api-parent',
      //   children: [
      //     {
      //       name: '家长代付产品介绍',
      //       componentName: 'api-parent-intro',
      //       component: () => import('./api/parent/intro.vue')
      //     },
      //     {
      //       name: '家长代付技术指导文档',
      //       componentName: 'api-parent-tech',
      //       component: () => import('./api/parent/tech.vue')
      //     },
      //     {
      //       name: '家长代付API文档',
      //       componentName: 'api-parent-api',
      //       component: () => import('./api/parent/api.vue')
      //     }
      //   ]
      // },
      {
        name: '账号授权',
        componentName: 'account',
        children: [
          {
            name: '账号接入技术指导文档',
            componentName: 'api-account-tech',
            component: () => import('./api/account/tech.vue')
          }
        ]
      }
    ]
  },
  {
    name: '审核规范',
    active: false,
    componentName: 'check',
    children: [
      {
        name: '应用名称和包名命名规范',
        componentName: 'check-name',
        component: () => import('./check/name.vue')
      },
      {
        name: '应用审核规范',
        componentName: 'check-rule',
        component: () => import('./check/rule.vue')
      },
      {
        name: '应用资质审核规范',
        componentName: 'check-quality',
        component: () => import('./check/quality.vue')
      },
      {
        name: '应用审核FAQ',
        componentName: 'check-faq',
        component: () => import('./check/faq.vue')
      },
      {
        name: '应用承诺函',
        componentName: 'check-promise',
        component: () => import('./check/promise.vue')
      },
      {
        name: 'APP备案说明',
        componentName: 'check-record',
        component: () => import('./check/record.vue')
      }
    ]
  },
  {
    name: '设计规范',
    active: false,
    componentName: 'design',
    children: [
      {
        name: '设计规范',
        componentName: 'design-rule',
        component: () => import('./design/rule.vue')
      }
    ]
  }
];
