<template>
  <div class="container">
    <h2>应用违规处罚规范</h2>
    <p class="text-title">
      <strong>1、处罚规则适用范围</strong>
    </p>
    <p>
      本规则适用于所有接入步步高平台的第三方应用。第三方应用在平台发布内容时，须遵守平台的内容规范及相关法律法规。
    </p>
    <p class="text-title">
      <strong>2、处罚依据</strong>
    </p>
    <p>处罚依据包括但不限于：</p>
    <p>
      <strong>2.1、平台方日常巡查：</strong>
    </p>
    <p>
      平台对所有第三方应用功能合规、内容合规等进行定期或不定期的巡查，发现不符合平台规范要求时依据本规则处罚。
    </p>
    <p>
      <strong>2.2、用户投诉：</strong>
    </p>
    <p>
      用户举报第三方应用中包含不良内容、诱导付费等不符合平台规范要求的，平台将对举报内容进行审核并根据情况进行处罚。
    </p>
    <p>
      <strong>2.3、外部舆情：</strong>
    </p>
    <p>
      因第三方应用涉及不良内容等问题而引发的负面舆论，影响平台声誉和用户信任的，平台将依据舆情影响程度采取相应处罚。
    </p>
    <p>
      <strong>2.4、官方监管：</strong>
    </p>
    <p>
      因第三方应用功能、内容、技术等要素，导致平台被官方约谈或处以包括行政处罚在内的其他监管措施，平台将依据相应情节的严重程度对第三方采取相应处罚。
    </p>
    <p class="text-title">
      <strong>3、处罚标准与执行措施</strong>
    </p>
    <p>
      <strong>3.1、平台日常巡查违规</strong>
    </p>
    <p>
      日常巡查是平台定期对应用进行的检查，主要关注是否存在违反平台规则、内容及功能不合规等情况，日常巡检中发现的一般违规行为参照本款处罚，日常巡检中发现的严重违规或重复违规行为参照本条3.5款执行。
    </p>
    <table>
      <tr>
        <td>
          <p><strong>违规频率</strong></p>
        </td>
        <td>
          <p><strong>处罚措施</strong></p>
        </td>
      </tr>
      <tr>
        <td><p>首次违规</p></td>
        <td>
          <p>
            1.下线违规内容：根据平台巡查发现的违规内容，要求开发者在规定的期限内完成违规内容的下线处理。
          </p>
          <p>
            2.全面排查与整改：除了下线具体违规内容外，还需对相关领域的潜在不良内容进行全面排查，确保类似问题得到彻底清除。开发者需提交详细的排查规则和下线处理报告，说明具体的整改措施及整改过程。
          </p>
        </td>
      </tr>
      <tr>
        <td><p>月度出现二次违规</p></td>
        <td>
          <p>1.限期整改，暂停该应用在步步高平台的推广资源一个季度。</p>
          <p>2.如整改不到位，考虑冻结应用分成，重新评估分成比例。</p>
        </td>
      </tr>
      <tr>
        <td><p>月度出现三次违规</p></td>
        <td>
          <p>1.限期整改，并出具整改报告；</p>
          <p>2.暂停该应用在步步高平台的推广资源半年；</p>
          <p>3.如整改不到位，考虑冻结应用分成，重新评估分成比例。</p>
        </td>
      </tr>
      <tr>
        <td><p>月度出现四次及以上违规</p></td>
        <td>
          <p>1.应用商店下架一个月；</p>
          <p>
            2.开发者提交整改报告及改进计划，经步步高审核通过后方可重新上架；
          </p>
          <p>3.全年不提供任何推广资源；</p>
        </td>
      </tr>
    </table>

    <p>
      <strong>3.2、用户投诉</strong>
    </p>
    <p>
      用户投诉第三方应用，且经核查确实存应用内容问题、隐私泄露、欺诈行为违反平台规则或违法问题的，用户投诉中发现的一般违规行为参照本款处罚，用户投诉中发现的严重违规或重复违规行为参照本条3.5款执行。
    </p>
    <table>
      <tr>
        <td>
          <p><strong>违规频率</strong></p>
        </td>
        <td>
          <p><strong>处罚措施</strong></p>
        </td>
      </tr>
      <tr>
        <td><p>首次投诉</p></td>
        <td>
          <p>1.平台要求开发者在规定时间内完成整改，并提供详细的整改报告；</p>
          <p>2.该应用暂停推广资源，直到问题得到有效解决；</p>
          <p>3.开发者需评估不合规问题整改周期并提交整改方案；</p>
          <p>
            4.若该投诉严重影响平台声誉或投诉涉及赔偿，平台有权要求开发者赔偿因违规产生的用户损失及平台损失。
          </p>
        </td>
      </tr>
      <tr>
        <td><p>二次投诉（以季度为单位）</p></td>
        <td>
          <p>1.该应用在应用商店下架一周，整改并提交详细报告；</p>
          <p>2.暂停该应用的推广资源一个季度；</p>
          <p>3.开发者需要重新评估不合规问题整改周期并提交整改方案；</p>
          <p>
            4.若该投诉严重影响平台声誉或投诉涉及赔偿，平台有权要求开发者赔偿因违规产生的用户损失及平台损失。
          </p>
        </td>
      </tr>
      <tr>
        <td><p>三次投诉及以上（以季度为单位）</p></td>
        <td>
          <p>1.该应用在应用商店下架两周，整改并提交详细报告；</p>
          <p>2.暂停该应用的推广资源半年；</p>
          <p>
            3.若该投诉严重影响平台声誉或投诉涉及赔偿，平台有权要求开发者赔偿因违规产生的用户损失及平台损失。
          </p>
        </td>
      </tr>
    </table>

    <p>
      注：投诉次数定义：同一类型问题开发者解决期间产生的客诉不重复计算，同一类型问题在同一时间重复发生多次的，基于平台判断可定性为严重违规。
    </p>
    <p>
      <strong>3.3、外部舆情</strong>
    </p>
    <p>
      外部舆情指的是公众舆论或媒体曝光的负面情况，可能对平台形象、用户信任产生影响。舆情分为一般舆情和严重舆情两类。
    </p>
    <ul>
      <li>
        <span class="dot">•</span>
        一般舆情：指的是在网络平台上出现某个应用相关的争议或负面讨论，但没有大规模传播，也未进入主流平台的热搜榜单。
      </li>
      <li>
        <span class="dot">•</span>
        严重舆情：指某个应用的违规或负面事件在主流社交平台（如抖音、快手、微博等）引发广泛关注进入热搜排行榜前50名，或相关报道阅读量大，经平台认定造成严重影响的，或官方媒体进行相关报道或转载的，造成社会影响和平台声誉风险。
      </li>
      <li>
        <span class="dot">•</span>
        外部舆情中发现的一般违规行为参照本款处罚，外部舆情中发现的严重违规或重复违规行为参照本条3.5款执行。
      </li>
    </ul>
    <table>
      <tr>
        <td>
          <p><strong>违规情况</strong></p>
        </td>
        <td>
          <p><strong>处罚措施</strong></p>
        </td>
      </tr>
      <tr>
        <td><p>一般舆情</p></td>
        <td>
          <p>1.平台要求开发者立即进行整改，解决舆论热点问题；</p>
          <p>
            2.如果舆情未得到有效控制，应用下架时间不限，并暂停所有推广资源；
          </p>
          <p>
            3.开发者需要在规定时间内提交详细整改报告，说明舆情产生原因及整改措施。
          </p>
        </td>
      </tr>
      <tr>
        <td><p>严重舆情</p></td>
        <td>
          <p>
            1.应用立即下架或停服，提交产品整改方案，平台方审核通过且舆情得到解决后另行协商上架时间；
          </p>
          <p>2.要求成立舆情处理应急小组，解决舆情问题并提交舆情解决方案；</p>
          <p>3.停止后续推广资源，包含预装、商店推广等；</p>
          <p>4.平台有权要求开发者赔偿平台遭受的直接损失。</p>
        </td>
      </tr>
    </table>

    <p>
      <strong>3.4、官方监管</strong>
    </p>
    <ul>
      <li>
        监管指政府等官方部门向平台发出的通知、组织约谈、实施警告、进行处罚等情形，除官方通知构成一般违规外，其他任何形式的官方监管均构成第三方应用严重违规。官方监管中发现的一般违规行为参照本款处罚，官方监管中发现的严重违规或重复违规行为参照本条3.5款执行。
      </li>
    </ul>
    <table>
      <tr>
        <td>
          <p><strong>违规情况</strong></p>
        </td>
        <td>
          <p><strong>处罚措施</strong></p>
        </td>
      </tr>
      <tr>
        <td><p>政府通知（全平台）</p></td>
        <td>
          <p>1.根据政府要求，立即下架相关应用并进行整改；</p>
          <p>2.提交整改报告、后续改进计划，接受平台及政府相关部门审查；</p>
          <p>3.暂停该应用的所有推广资源，直到问题完全解决。</p>
        </td>
      </tr>
      <tr>
        <td><p>政府通知（单独就步步高平台）</p></td>
        <td>
          <p>1.立即下架不合规内容，并提交整改报告；</p>
          <p>2.下架一周，并提交后续优化方案；</p>
          <p>
            3.暂停所有推广资源，整改报告经政府或平台审核通过后才可恢复上线；
          </p>
          <p>4.严重情况下，平台有权要求开发者赔偿平台遭受的直接损失。</p>
        </td>
      </tr>
    </table>

    <p>
      <strong>3.5、严重或重复违规情形</strong>
    </p>
    <p>对于发生严重违规或整改后的再次违规情况，应采取更严格的措施。</p>
    <table>
      <tr>
        <td>
          <p><strong>违规情形</strong></p>
        </td>
        <td>
          <p><strong>处罚措施</strong></p>
        </td>
      </tr>
      <tr>
        <td><p>发生严重违规或同一违规行为重复发生；</p></td>
        <td>
          <p>
            1.立即下架，并要求开发者进行整改，上线时间由步步高审核确认；
          </p>
          <p>2.赔偿平台因此遭受的全部损失；</p>
          <p>3.步步高有权终止合作；</p>
          <p>4.不再提供任何推广资源。</p>
        </td>
      </tr>
    </table>

    <p>注：事件严重程度及匹配的处罚措施最终解释权为步步高平台。</p>
    <p>
      注：重复指普通违规行为整改后在同季度内再次发生相同违规行为的，或严重违规行为整改后在三年内再次发生同类违规行为的。
    </p>
    <p></p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  background: #fff !important;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  padding-bottom: 40px;
  color: #666;
  font-size: 14px;
  line-height: 30px;

  .text-title {
    font-size: 16px;
  }

  .dot {
    margin: 0 8px;
    font-size: 18px;
    color: #000;
  }

  h2,
  h3,
  h4 {
    color: #333333;
    line-height: 60px;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
  h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 100;
  }
  strong {
    font-weight: 600;
    color: #000;
  }
  p {
    color: #666;
    font-size: 14px;
  }
  p + div {
    padding-left: 2em;
  }
  p + div {
    & > p {
      text-indent: 2em;
    }
  }
  // h3 + p,
  // h4 + p {
  //   text-indent: 2em;
  // }
  // h3 + div,
  // h4 + div {
  //   padding-left: 2em;
  // }
  h4 {
    margin: 5px 0;
    text-indent: 1em;
    font-weight: 100;
    font-size: 16px;
  }
  h2 + div,
  h3 + div,
  h4 + div {
    & > p {
      text-indent: 2em;
    }
  }
  table {
    border-left: 1px solid #666;
    border-top: 1px solid #666;
    th,
    td {
      border-bottom: 1px solid #666;
      border-right: 1px solid #666;
      padding: 5px;
    }
  }
}
</style>
