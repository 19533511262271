<template>
  <section class="customize">
    <div class="body-wrap">
      <div class="main-body">
        <div class="url-navigation">
          <span class="back" @click="$router.back()">返回上一页</span> |
          <span class="current-page" @click="$router.replace('/customize')"
            >定制详情</span
          >
        </div>
        <div class="data-display">
          <h3>
            应用列表
            <a
              style="color: #3BBFFF;margin-right: 42px;font-weight: 100;"
              href="https://common-file-dn.eebbk.net/common-file/overwrite/%E5%95%86%E5%BA%97%E8%AF%A6%E6%83%85%E9%A1%B5%E5%88%B6%E4%BD%9C%E8%A7%84%E8%8C%83.psd"
              >应用详情设计规范及PSD模板下载</a
            >
          </h3>
          <div class="table">
            <div class="table-title">
              <div>应用名</div>
              <div>定制状态</div>
              <div>审核状态</div>
              <div>操作</div>
            </div>
            <div
              v-for="item in serviceData"
              :key="item.apkPackageName"
              class="table-body"
            >
              <div>{{ item.apkName }}</div>
              <div v-html="getCusomizeStatus(item.checkStatus)"></div>
              <div>
                <span v-html="getCheckStatus(item.checkStatus)"></span>
                <el-tooltip
                  v-if="item.checkStatus == 2"
                  class="item"
                  effect="dark"
                  placement="top-start"
                >
                  <img src="@/assets/img/service-intro.png" alt="" />
                  <div slot="content" style="width: 200px;">
                    {{ item.reason }}
                  </div>
                </el-tooltip>
              </div>
              <div>
                <span
                  v-if="item.checkStatus && item.checkStatus == 3"
                  @click="openConfirmDialog(item)"
                  >撤销定制</span
                >
                <span v-if="item.checkStatus == 1" style="color: #ccc;">/</span>
                <span
                  v-if="
                    !item.checkStatus ||
                      item.checkStatus == 4 ||
                      item.checkStatus == 2
                  "
                  @click="openUploadDialog(item)"
                  >上传图片</span
                >
              </div>
            </div>
          </div>
          <el-pagination
            v-if="serviceData.length > 0"
            class="pagination"
            :current-page="pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="'是否撤销定制' + cancelItem.apkName"
      :visible.sync="shouldShowCancelModal"
      top="30vh"
      width="30%"
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="shouldShowCancelModal = false">取 消</el-button>
        <el-button type="primary" @click="shouldShowCancelModal = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="shouldShowUploadModal" top="20vh" width="1000px">
      <h4 slot="title" style="text-align: center">上传图片</h4>
      <div style="display: flex;justify-content: center">
        <div class="wrap-upload">
          <div class="desc">
            <img
              src="https://common-pic-dn.eebbk.net/common-pic/2019/12/14/115049083_03060712e81c0f7e.png"
              alt=""
            />
            <span style="vertical-align: middle;">竖屏:</span>
          </div>
          <div class="bottom">
            <div class="imgurl">
              <p style="line-height: 50px;">768x1080</p>
              <!-- <p style="line-height: 50px;" v-if="ishaveSexiocn1 == false ? true : false">768x1080</p>
              <p v-if="ishaveSexiocn1" style="line-height: 25px;color:red;display:flex;width: 132px;">上传失败：检测到此图片可能存在色情敏感信息</p> -->
              <div
                class="plus-upload"
                :class="{ 'start-upload': imgStart1, 'end-upload': imgEnd1 }"
                @click="uploadImg(1)"
              >
                <span v-if="imgUrl1" @click.stop="clearImgUrl(1)">x</span>
                <img v-if="imgUrl1" class="imgurl1" :src="imgUrl1" />
                <div v-else class="plus"></div>
              </div>
              <p>竖屏效果图</p>
              <p class="imgdesc">（此图用于效果审核）</p>
            </div>
            <div class="imgurl">
              <p style="line-height: 50px;">1536x1024</p>
              <!-- <p style="line-height: 50px;" v-if="ishaveSexiocn2 == false ? true : false">1536x1024</p>
              <p v-if="ishaveSexiocn2" style="line-height: 25px;;color:red;display:flex;width: 132px;">上传失败：检测到此图片可能存在色情敏感信息</p> -->
              <div
                class="plus-upload"
                :class="{ 'start-upload': imgStart2, 'end-upload': imgEnd2 }"
                @click="uploadImg(2)"
              >
                <span v-if="imgUrl2" @click.stop="clearImgUrl(2)">x</span>
                <img v-if="imgUrl2" class="imgurl2" :src="imgUrl2" />
                <div v-else class="plus"></div>
              </div>
              <p>竖屏背景图</p>
              <p class="imgdesc">（此图用于主题定制）</p>
            </div>
          </div>
        </div>
        <div class="wrap-upload">
          <div class="desc">
            <img
              src="https://common-pic-dn.eebbk.net/common-pic/2019/12/14/115049060_942ffd0c62b6f97d.png"
              alt=""
            />
            <span style="vertical-align: middle;">横屏:</span>
          </div>
          <div class="bottom">
            <div class="imgurl">
              <p style="line-height: 50px;">1024x1080</p>
              <!-- <p style="line-height: 50px;" v-if="ishaveSexiocn3 == false ? true : false">1024x1080</p>
              <p v-if="ishaveSexiocn3" style="line-height: 25px;;color:red;display:flex;width: 132px;">上传失败：检测到此图片可能存在色情敏感信息</p> -->
              <div
                class="plus-upload"
                :class="{ 'start-upload': imgStart3, 'end-upload': imgEnd3 }"
                @click="uploadImg(3)"
              >
                <span v-if="imgUrl3" @click.stop="clearImgUrl(3)">x</span>
                <img v-if="imgUrl3" class="imgurl3" :src="imgUrl3" />
                <div v-else class="plus"></div>
              </div>
              <p>横屏效果图</p>
              <p class="imgdesc">（此图用于效果审核）</p>
            </div>
            <div class="imgurl">
              <p style="line-height: 50px;">2048x1124</p>
              <!-- <p style="line-height: 50px;" v-if="ishaveSexiocn4 == false ? true : false">2048x1124</p>
              <p v-if="ishaveSexiocn4" style="line-height: 25px;;color:red;display:flex;width: 132px;">上传失败：检测到此图片可能存在色情敏感信息</p> -->
              <div
                class="plus-upload"
                :class="{ 'start-upload': imgStart4, 'end-upload': imgEnd4 }"
                @click="uploadImg(4)"
              >
                <span v-if="imgUrl4" @click.stop="clearImgUrl(4)">x</span>
                <img v-if="imgUrl4" class="imgurl4" :src="imgUrl4" />
                <div v-else class="plus"></div>
              </div>
              <p>横屏背景图</p>
              <p class="imgdesc">（此图用于主题定制）</p>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" style="text-align: center;">
        <el-button
          :disabled="!imgUrl1 || !imgUrl2 || !imgUrl3 || !imgUrl4"
          type="primary"
          @click="submitAddData"
          >提交审核</el-button
        >
        <el-button @click="shouldShowUploadModal = false">取 消</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
export default {
  name: 'Customize',
  data() {
    return {
      userInfo: {},
      // 查询结果总数
      totalCount: 0,
      // 页数
      pageNum: 1,
      // 每页显示条数，
      pageSize: 10,
      // 当前显示数据
      serviceData: [],
      // 撤销定制modal显隐
      shouldShowCancelModal: false,
      // 需要撤销定制的当前应用
      cancelItem: {},
      // 上传图片modal
      shouldShowUploadModal: false,
      // 竖屏效果图
      imgUrl1: '',
      // 竖屏定制图
      imgUrl2: '',
      // 横屏效果图
      imgUrl3: '',
      // 横屏定制图
      imgUrl4: '',
      imgStart1: false,
      imgStart2: false,
      imgStart3: false,
      imgStart4: false,
      imgEnd1: false,
      imgEnd2: false,
      imgEnd3: false,
      imgEnd4: false
      // ishaveSexiocn1:false,
      // ishaveSexiocn2:false,
      // ishaveSexiocn3:false,
      // ishaveSexiocn4:false,
    };
  },
  computed: {
    // 计算当前显示数据在所有数据的开始索引 40   3    20
    startIndex() {
      return this.pageSize * (this.pageNum - 1);
    },
    endIndex() {
      if (this.pageSize * this.pageNum - 1 > this.totalCount - 1) {
        return this.totalCount - 1;
      }
      return this.pageSize * this.pageNum - 1;
    }
  },
  watch: {
    pageSize() {
      let maxPageNum = Math.ceil(this.totalCount / this.pageSize);
      if (this.pageNum > maxPageNum) {
        this.pageNum = maxPageNum;
      }
      this.getData();
    },
    shouldShowUploadModal(newVal) {
      if (!newVal) {
        // 竖屏效果图
        this.imgUrl1 = '';
        // 竖屏定制图
        this.imgUrl2 = '';
        // 横屏效果图
        this.imgUrl3 = '';
        // 横屏定制图
        this.imgUrl4 = '';
        this.imgStart1 = false;
        this.imgStart2 = false;
        this.imgStart3 = false;
        this.imgStart4 = false;
        this.imgEnd1 = false;
        this.imgEnd2 = false;
        this.imgEnd3 = false;
        this.imgEnd4 = false;
      }
    }
  },
  mounted() {
    // 权限鉴定
    this.$axios.get('/appManage/getCooperationSwitchShow').then(res => {
      if (!res.data.data[1].serviceSwitch) {
        // 无权限触发404
        this.$router.replace('/noPermisionForbiden403');
      }
    });
    // 获取存储用户accountId
    this.$message({
      message: '数据加载中...',
      duration: 0
    });
    this.$axios('/userManage/getUserInfo', {
      method: 'POST'
    })
      .then(res => {
        this.userInfo.accountId = res.data.data.accountId;
        this.getList();
      })
      .catch(e => {
        this.$message.closeAll();
        this.$message({
          message: '数据加载失败',
          duration: 1000
        });
      });
  },
  methods: {
    // 改变每页显示数据条数
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize;
    },
    // 根据页码获取数据
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.getData();
    },
    // 从计算的索引替换当前显示数据
    getData() {
      this.serviceData = this.ALLQUERYDATA.slice(
        this.startIndex,
        this.endIndex + 1
      );
    },
    // 打开撤销定制确认弹框
    openConfirmDialog(item) {
      this.$confirm('确定要撤销' + item.apkName + '的详情页定制吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios
          .post('/apkDetailImgManage/cancelAppDetailImg?id=' + item.id)
          .then(res => {
            this.$message({
              type: 'success',
              message: '撤销成功!'
            });
            this.getList();
          });
      });
    },
    // 打开上传modal
    openUploadDialog(item) {
      this.uploadItem = item;
      this.shouldShowUploadModal = true;
    },
    // 获取详情定制文本
    getCusomizeStatus(status) {
      if (status == 3) {
        return '<span>已定制</span>';
      }
      return "<span style='color: #ccc'>未定制</span>";
    },
    // 获取审核状态文本
    getCheckStatus(status) {
      switch (status) {
        case 1:
          return "<span style='color: #3CB1FF'>审核中</span>";
        case 2:
          return "<span style='color: red'>审核不通过</span>";
        case 3:
          return "<span style='color: rgb(96, 231, 114)'>审核通过</span>";
        default:
          return "<span style='color: #ccc'>/</span>";
      }
    },
    // 上传图片
    uploadImg(index) {
      if (this['imgUrl' + index]) {
        return;
      }
      let self = this;
      let inputEl = document.createElement('input');
      inputEl.setAttribute('type', 'file');
      inputEl.setAttribute('accept', 'image/png');
      inputEl.click();
      inputEl.onchange = function(e) {
        // 开始上传
        self['imgStart' + index] = true;
        let file = inputEl.files[0];
        if (file.size > 3145728) {
          self.$message({
            message: '图片超过限定大小，请上传大小为3MB以内的图片',
            type: 'error'
          });
          return;
        }
        if (index === 2) {
          self.getFileUrl(file);
        }
        let formData = new FormData();
        formData.append('imgType', index);
        formData.append('file', file);

        self.$axios
          .post('/apkDetailImgManage/uploadAppDetailImg', formData)
          .then(res => {
            if (res.data.stateCode == 0) {
              let img = new Image();
              // img.crossOrigin = "Anonymous";
              img.src = res.data.data.path;
              img.onload = function() {
                self['imgEnd' + index] = true;
                self['imgStart' + index] = false;
                self['imgUrl' + index] = res.data.data.path;
              };
              // if(res.data.data.stateCode && res.data.data.stateCode == '1009') {
              //   let img = new Image();
              //   img.src = res.data.data.path;
              //   img.onload = function() {
              //     self['imgEnd' + index] = true;
              //     self['imgStart' + index] = false;
              //     self['imgUrl' + index] = res.data.data.path;
              //     self['ishaveSexiocn' + index] = true;
              //   };
              // } else {
              //   let img = new Image();
              //   img.src = res.data.data.path;
              //   img.onload = function() {
              //     self['imgEnd' + index] = true;
              //     self['imgStart' + index] = false;
              //     self['imgUrl' + index] = res.data.data.path;
              //   };
              // }
            } else {
              self['imgEnd' + index] = false;
              self['imgStart' + index] = false;
              self.$message({
                message: res.data.stateInfo,
                type: 'error'
              });
            }
          });
      };
    },
    // 删除重新上传
    clearImgUrl(index) {
      this['imgUrl' + index] = '';
      // this['imgStart1' + index] = false;
      // this['imgEnd' + index] = false;
      // this['ishaveSexiocn' + index] = false;
    },
    clearCatch() {
      for (let i of [1, 2, 3, 4]) {
        this['imgUrl' + i] = '';
        this['imgStart' + i] = false;
        this['imgEnd' + i] = false;
      }
    },
    // 提交审核
    submitAddData() {
      this.$axios
        .post('/apkDetailImgManage/addAppDetailImg', {
          apkPackageName: this.uploadItem.apkPackageName,
          verticalImg: this.imgUrl1,
          verticalBg: this.imgUrl2,
          horizontalImg: this.imgUrl3,
          horizontalBg: this.imgUrl4,
          appealAccountId: this.$cookie.getCookie('accountId'),
          rgbaColor: this.imgColor
        })
        .then(res => {
          if (res.data.stateCode == 0) {
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.shouldShowUploadModal = false;
            setTimeout(() => {
              this.getList();
            }, 500);
          } else {
            this.$message({
              message: res.data.stateInfo,
              type: 'error'
            });
          }
        });
    },
    // 图片跨域不可取， 直接从文件下手
    getFileUrl(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      if (!url) {
        alert('您的浏览器版本过低,这将会影响正常功能使用');
        return;
      }
      let img = new Image();
      let self = this;
      img.src = url;
      img.onload = function() {
        self.getImageColor(img);
      };
    },
    // 获取图片最下角颜色
    getImageColor(img) {
      let canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let rgba = ctx.getImageData(img.width - 1, img.height - 1, 1, 1);
      this.imgColor = this.hexify(rgba.data);
    },
    // 转化rgba为十六进制
    hexify(color) {
      var values = color;
      var a = parseFloat(values[3] || 1),
        r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
        g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
        b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
      return (
        '#' +
        ('0' + r.toString(16)).slice(-2) +
        ('0' + g.toString(16)).slice(-2) +
        ('0' + b.toString(16)).slice(-2)
      );
    },
    // 获取数据
    getList() {
      // 获取数据
      this.$axios
        .get('/apkDetailImgManage/getAppDetailImgList', {
          params: {
            pi: 1,
            ps: 1000
          }
        })
        .then(res => {
          this.$message.closeAll();
          this.ALLQUERYDATA = res.data.data.list;
          this.totalCount = res.data.data.list.length;
          this.getData();
          this.$forceUpdate();
        })
        .catch(_ => {
          this.$message.closeAll();
          this.$message({
            message: '数据加载失败',
            duration: 1000
          });
        });
    }
  }
};
</script>

<style lang="less" scoped>
.body-wrap {
  background: #f2f2f2;
  .main-body {
    width: 100%;
    min-height: 900px;
    position: relative;
    clear: both;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 1200px;
    margin: 0 auto;
    .url-navigation {
      font-size: 16px;
      padding-bottom: 20px;
      .back {
        color: #666;
        cursor: pointer;
      }
      .current-page {
        color: blue;
        cursor: pointer;
      }
    }
    .data-display {
      background: #fff;
      h3 {
        position: relative;
        font-size: 16px;
        padding-top: 29px;
        padding-left: 42px;
        padding-bottom: 35px;
        display: flex;
        justify-content: space-between;
        &::before {
          content: '';
          position: absolute;
          top: 32px;
          left: 31px;
          width: 4px;
          height: 16px;
          border-radius: 3px;
          background: #3cb1ff;
        }
      }
      .table {
        width: 1115px;
        margin: 0 auto;
        text-align: center;
        border-radius: 5px;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        .table-title {
          display: flex;
          font-size: 18px;
          background: #f0f4f5;
          div {
            position: relative;
            line-height: 70px;
            flex: 0 0 auto;
            width: 25%;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
        }
        .table-body {
          display: flex;
          font-size: 14px;
          div {
            position: relative;
            display: block;
            flex: 0 0 auto;
            width: 25%;
            line-height: 49px;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            &:last-of-type {
              color: #3cb1ff;
              cursor: pointer;
              &:hover {
                color: #3991f4;
              }
            }
            img {
              vertical-align: middle;
              margin-top: -3px;
              margin-left: 3px;
            }
          }
        }
      }
      .pagination {
        width: 1115px;
        margin: 0 auto;
        padding: 21px 0 29px;
        text-align: right;
      }
    }
  }
}
.wrap-upload {
  display: inline-block;
  padding: 0 50px;
  box-sizing: border-box;
  .desc {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
    img {
      margin-right: 10px;
    }
  }
  .plus-upload {
    position: relative;
    min-width: 130px;
    min-height: 130px;
    border-radius: 10px;
    border: 1px dashed #ccc;
    transition: all 0.3s linear;
    text-align: center;
    cursor: pointer;
    &:hover {
      border-color: #3cb1ff;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 3px;
      left: 10%;
      right: 10%;
      width: 0%;
      height: 3px;
      border-radius: 3px;
      background: #94e594;
      transition: all 3s linear;
    }
    &.start-upload {
      &::after {
        width: 70%;
      }
    }
    &.end-upload {
      &::after {
        width: 80%;
        transition: all 0.3s linear;
      }
    }
    .plus {
      position: absolute;
      width: 100%;
      height: 100%;
      &::before {
        content: ' ';
        position: absolute;
        width: 2px;
        height: 40px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #ccc;
      }
      &::after {
        content: ' ';
        position: absolute;
        height: 2px;
        width: 40px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #ccc;
      }
    }
    .imgurl1 {
      width: 60%;
      height: 85%;
    }
    .imgurl2 {
      width: 80%;
      height: 53%;
    }
    .imgurl3 {
      width: 75%;
      height: 75%;
    }
    .imgurl4 {
      width: 80%;
      height: 40%;
    }
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    span {
      position: absolute;
      right: 3px;
      top: 3px;
      width: 15px;
      height: 15px;
      text-align: center;
      line-height: 13px;
      font-size: 12px;
      border-radius: 50%;
      border: 1px solid #ccc;
      box-sizing: border-box;
      transition: all 0.3s linear;
      cursor: pointer;
      &:hover {
        background: red;
        color: #fff;
        border-color: #fff;
      }
    }
  }
  .bottom {
    display: flex;
    font-size: 12px;
    .imgurl:first-child {
      margin-right: 20px;
    }
    .imgdesc {
      color: #2fdb9d;
    }
    p {
      line-height: 30px;
    }
  }
}
</style>
